
import './App.css';
import MovieListings from './MovieListings'



function App() {
  return (
    <div className="App">
      <header className="App-header">
      <center>
        <h1>Assignment # 1</h1>
        <h3>Name: Sameel Butt - Roll No: 201370025</h3>
        <hr></hr>
        <br></br>
        <h3>List of all movies</h3>
      </center>

      <MovieListings />
      
      </header>
    </div>
  );
}

export default App;
